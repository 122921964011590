import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Box,
  Dialog,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  Link,
  useMediaQuery,
  DialogTitle
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import OtpInput from 'react-otp-input';
import { verifyOtp, resendOtp, setAlertInfo, setIsVerified, setIsOpen, setUserData } from '../../store/actions';
//import styles
import styles from './styles';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const OtpDialog = (props) => {
  const { id, open, handleClose, rEmail, rPhone, history, setIsOpen, setAlertInfo, userData, selectedSeats, listAllShow, userToken, setUserData, user } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = styles();

  const [otp_code, Setotp_code] = useState('');
  const [haserror, SetHaserror] = useState(false);
  const [mainHelper, SetMainHelper] = useState({
    isError: false,
    helperText: ''
  });
  const [resendClicked, SetresendClicked] = useState(false);
  const [disableotp, SetOtpDisable] = useState(false);
  const [resend_status, SetResendStatus] = useState({
    isError: false,
    helpertext: '',
    msg_type: ''
  });
  const [backdropOpen, SetbackdropOpen] = useState(false);
  // Alert calls for 2sec visibility
  const onShowAlert = (status, helperText, type) => {
    SetResendStatus({
      isError: status,
      helpertext: helperText,
      msg_type: type
    });
    window.setTimeout(() => {
      SetResendStatus({
        isError: false,
        helpertext: '',
        msg_type: ''
      });
    }, 3000);
  };

  const handleChange = (otp) => {
    SetHaserror(false);
    SetMainHelper({ isError: false, helperText: '' });
    Setotp_code(otp);
  };

  useEffect(() => {
    if (otp_code.length == 6) {
      SetOtpDisable(true);
      SetbackdropOpen(true);

      if (rPhone) {
        props.verifyOtp(rPhone, rEmail, otp_code)
          .then(async (output) => {
            SetbackdropOpen(false)
            if (!output?.isSuccess) {
              SetMainHelper({ isError: true, helperText: output.msg });
              SetOtpDisable(false)
            } else {
              setIsVerified(true)
              SetbackdropOpen(true)
              const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
              const url = `${process.env.REACT_APP_BE_URL}/bookings/book`
              axios.post(url, {
                fullName: userData?.name,
                email: userData?.email,
                phone: userData?.phone,
                seatInfo: selectedSeats,
                listAllShowId: listAllShow?.showDateAndTime,
                userToken: userToken?.userToken
              }).then((res) => {
                setTimeout(() => {
                  SetbackdropOpen(false)
                }, 5000);
                const result = stripe.redirectToCheckout({
                  sessionId: res?.data?.data?.id,
                });
                if (result.error) {
                  const alertinfo = {
                    content: result?.error,
                    dialogType: '',
                    title: 'A kind Info'
                  }
                  setAlertInfo(alertinfo)
                  setIsOpen(true)
                }
              }).catch((err) => {
                console.log(err)
                SetbackdropOpen(false)
                const alertinfo = {
                  content: err?.response?.data?.message,
                  dialogType: '',
                  title: 'A kind Info'
                }
                setAlertInfo(alertinfo)
                setIsOpen(true)
              })
            }
          }).catch((error) => {
          })
      } else if (rEmail) {
        props.verifyOtp(rPhone, rEmail, otp_code)
          .then(async (output) => {
            SetbackdropOpen(false)
            if (!output?.isSuccess) {
              SetHaserror(true);
              SetMainHelper({ isError: true, helperText: output.msg });
              SetOtpDisable(false)
            } else {
              setIsVerified(true)
              SetbackdropOpen(true)
              const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
              const url = `${process.env.REACT_APP_BE_URL}/bookings/book`
              axios.post(url, {
                fullName: userData?.name,
                email: userData?.email,
                phone: userData?.phone,
                seatInfo: selectedSeats,
                listAllShowId: listAllShow?.showDateAndTime,
                userToken: userToken?.userToken
              }).then((res) => {
                SetbackdropOpen(false)
                const result = stripe.redirectToCheckout({
                  sessionId: res?.data?.data?.id,
                });
                if (result.error) {
                  const alertinfo = {
                    content: result?.error,
                    dialogType: '',
                    title: 'A kind Info'
                  }
                  setAlertInfo(alertinfo)
                  setIsOpen(true)
                }
              }).catch((err) => {
                SetbackdropOpen(false)
                const alertinfo = {
                  content: err.response.data.message,
                  dialogType: '',
                  title: 'A kind Info'
                }
                setAlertInfo(alertinfo)
                setIsOpen(true)
              })
            }
          }).catch((error) => {
          })
      }
    }
  }, [otp_code]);

  const resendOtp = () => {
    SetMainHelper({ isError: false, helperText: '' });
    SetHaserror(false);
    SetresendClicked(true);
    Setotp_code('')
    SetOtpDisable(false)

    if (rPhone && rEmail) props.resendOtp(rPhone, rEmail)
      .then((output) => {
        if (!output?.isSuccess) {
          SetHaserror(true);
          SetMainHelper({ isError: true, helperText: output.msg });
          SetOtpDisable(true)
        }
        setTimeout(() => {
          SetresendClicked(false);
        }, 1000)
      }).catch((error) => {
      })
  };

  return (
    <div>
      <Dialog
        onClose={() => {
          Setotp_code('');
          SetHaserror(false);
          SetOtpDisable(false);
          SetMainHelper({
            isError: false,
            helperText: ''
          });
          handleClose();
        }}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby={id}
        maxHeight="sm"
        style={fullScreen ? { top: 'auto', bottom: 0 } : {}}
        scroll={'body'}>
        <Backdrop className={classes.backdrop} open={backdropOpen}>
          <CircularProgress color="primary" size={70} thickness={5} />
        </Backdrop>
        <DialogTitle id={id}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              handleClose();
              Setotp_code('');
              SetHaserror(false);
              SetOtpDisable(false);
              SetMainHelper({
                isError: false,
                helperText: ''
              });
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleClose();
            Setotp_code('');
            SetHaserror(false);
            SetOtpDisable(false);
            SetMainHelper({
              isError: false,
              helperText: ''
            });
          }}></DialogTitle> */}
        <Box>
          <Container>
            <Grid container>
              <Grid item xs={12} className={classes.item_content}>
                <div className={classes.otp_wrapper}>
                  <Typography variant="h6" className={classes.succes_title}>
                    Otp verification
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    className={classes.apply_job_sub_title}>
                    {rEmail && rPhone
                      ? `We have sent otp code to your email ${rEmail} and phone ${rPhone}. please enter one of them to proceed.`
                      : rEmail
                        ? `We have sent otp code to your email ${rEmail}. please enter otp code to proceed.`
                        : rPhone
                          ? `We have sent otp code to your phone ${rEmail}. please enter otp code to proceed.`
                          : `We have sent otp code to your verified mediums. please enter otp code.`}
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <OtpInput
                      inputStyle={classes.input_otp_style}
                      value={otp_code}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                      isInputNum={true}
                      hasErrored={haserror}
                      errorStyle={classes.error_otp}
                      focusStyle={haserror ? '' : classes.focus_otp}
                      isDisabled={disableotp}
                      disabledStyle={classes.disable_style}
                      shouldAutoFocus
                    />
                  </div>

                  {mainHelper.isError && (
                    <p className={classes.main_error_text}>
                      {mainHelper.helperText}
                    </p>
                  )}
                  {resend_status.isError && (
                    <p
                      className={
                        resend_status.msg_type
                          ? classes.main_success_text
                          : classes.main_error_text
                      }>
                      {'\u2003'}
                      {resend_status.helpertext}
                    </p>
                  )}
                  <div className={classes.otp_resend_wrapper}>
                    {!resendClicked ? (
                      <>
                        {' '}
                        <Typography
                          variant="h6"
                          className={classes.small_title}>
                          I didn't receive the code -
                        </Typography>
                        <Link component="button" onClick={resendOtp}>
                          Resend
                        </Link>
                      </>
                    ) : (
                      <Typography variant="h6" className={classes.small_title}>
                        Sending...
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authState.isAuthenticated,
  userData: state.userDataState.userData,
  userToken: state.userDataState.userToken,
  selectedSeats: state.userDataState.selectedSeats,
  listAllShow: state.listAllShowState.listAllShow,
  user: state.authState.user
});
export default connect(mapStateToProps, { verifyOtp, setIsVerified, resendOtp, setIsOpen, setUserData, setAlertInfo })(OtpDialog);
